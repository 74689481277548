<template>
  <div class="salarySiginPassword">
    <van-nav-bar
      :title="title + '（' + number + '）'"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      :fixed="true"
    />
    <div class="fixedH" style="height: 1.22667rem"></div>
    <div class="content">
      <div class="userInfoBox">
        <div class="userInfoLeft">
          <p v-for="(value, key) in topData" :key="key">
            <span class="userKey">{{ value.chnname }}</span>
            <span class="userVal">{{ value.fval }}</span>
          </p>
        </div>
        <div class="userInfoRight">
          <div class="svg_sigin" v-if="fieldobj.is_wxsign">
            <svg-icon icon-class="zu820"></svg-icon>
          </div>
          <div class="default_avator">
            <img :src="fieldobj.empurl" alt="" />
          </div>
        </div>
      </div>
      <div class="userSalaryInfo">
        <van-collapse v-model="activeNames">
          <van-collapse-item
            :title="item.name"
            v-for="(item, index) in resData"
            :key="index"
            :name="index"
          >
            <div class="salaryContent">
              <p v-for="(val, key) in item.data" :key="key">
                <span class="salaryKey" v-if="val.controltype != 'memo'">{{ val.chnname }}</span>
                <span class="salaryVal">{{ val.fval }}</span>
              </p>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="userSignBox" v-if="fieldobj.url">
        <div class="signTitle">
          <span>{{ $t("salaryDetails.sdTxt1") }}</span>
        </div>
        <div class="imgBox">
          <img :src="fieldobj.url" alt="" />
        </div>
      </div>
      <van-overlay :show="show" @click="show = false">
        <div style="width: 100%; height: 100%" class="wrapper" @click.stop>
          <signaturePad
            @getSpad="getSpad"
            @closeSpad="closeSpad"
            :show="show"
          ></signaturePad>
        </div>
      </van-overlay>
    </div>
    <div class="footerBox">
      <div
        class="allSiginButton"
        @click="show = true"
        v-if="!fieldobj.is_wxsign"
      >
        {{ $t("salaryDetails.sdTxt2") }}
      </div>
      <div
        class="allSiginButton"
        @click="onClickLeft"
        v-if="fieldobj.is_wxsign"
      >
        {{ $t("salaryDetails.sdTxt3") }}
      </div>
    </div>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { Dialog, Toast } from "vant";
import signaturePad from "@components/signaturePad";
import { getEmpMonthSalaryDetail, signMonthSalarySgl } from "@api/wx.js";
export default {
  data() {
    return {
      userInfo,
      show: false,
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      yymm: "",
      title: "",
      resData: [],
      fieldobj: {},
      topData: [],
      number: "",
    };
  },
  components: {
    signaturePad,
  },
  watch: {},
  created() {
    this.title = this.$route.query.china;
    this.number = this.$route.query.num;
    this.getData();
  },
  methods: {
    onClickLeft() {
      this.$router.push({
        path: "/salaryMonth",
        query: { yymm: this.$route.query.yymm, china: this.$route.query.china },
      });
    },
    onClickRight() {},
    closeSpad() {
      this.show = false;
    },
    classifyArr(arr, param) {
      var map = {},
        dest = [];
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i];
        if (ai[param] && !map[ai[param]]) {
          dest.push({ name: ai[param], data: [ai] });
          map[ai[param]] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.name == ai[param]) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }
      return dest;
    },
    getData() {
      getEmpMonthSalaryDetail({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username,
      }).then((res) => {
        let newData = [];
        let oldData = [];
        let fieldlist = res.fieldlist;
        this.fieldobj = res.fieldvalue[0];
        for (let i = 0; i < fieldlist.length; i++) {
          for (let j in this.fieldobj) {
            if (fieldlist[i].fieldname == j) {
              fieldlist[i].fval = this.fieldobj[j];
            }
          }
          if (fieldlist[i].iflag == 1) {
            newData.push(fieldlist[i]);
          } else {
            oldData.push(fieldlist[i]);
          }
        }
        this.topData = newData;
        this.resData = this.classifyArr(oldData, "groupname");
      });
    },
    getSpad(url) {
      signMonthSalarySgl({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username,
        filebase64str: url,
      }).then((res) => {
        console.log(res);
        if (res.iserror == 0) {
          Toast(this.$t("salaryDetails.sdTxt4"));
          this.show = false;
          this.getData(this.yymm);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .footerBox {
    height: 132px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .allSiginButton {
      width: 60%;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #2b8df0;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 224px);
    overflow: auto;
    .userInfoBox {
      width: 100%;
      background: #fff;
      display: flex;
      padding: 0 0.42667rem;
      margin-top: 20px;
      p {
        width: 100%;
        margin-top: 0;
        margin-bottom: 25px;
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        .userKey {
          font-size: 32px;
          color: #999;
        }
        .userVal {
          font-size: 32px;
          color: #333;
          margin-left: 42px;
        }
      }
    }
    .userInfoLeft {
      width: 50%;
      min-height: 150px;
      padding-top: 25px;
    }
    .userSalaryInfo {
      margin-bottom: 20px;
    }
    .userInfoRight {
      width: 50%;
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .svg_sigin {
        width: 130px;
        height: 130px;
        .svg-icon {
          width: 100%;
          height: 100%;
        }
      }
      .default_avator {
        width: 100px;
        height: 100px;
        margin-left: 15px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 100px;
        }
      }
    }
    .userSignBox {
      width: 100%;
      background: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      .signTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 28px;
        color: #666;
        height: 60px;
        padding-left: 3%;
        padding-right: 4%;
      }
      .imgBox {
        width: 100%;
        background: #fff;
        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
        }
      }
    }
    .userSalaryInfo {
      /deep/.van-collapse-item {
        margin-top: 20px;
      }
      /deep/.van-cell__title {
        span {
          font-weight: bold;
        }
      }
      /deep/.van-collapse-item__content {
        padding: 0 0.42667rem;
      }
    }
    .salaryContent {
      background: #fff;
      padding: 20px 0 1px 0;
      p {
        margin: 0;
        margin-bottom: 20px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .salaryVal {
        color: #2b8df0;
        text-align: justify;
      }
    }
  }
}
</style>